<!--能人列表-->
<!--能人列表 跳转到咨询类详情 , 有人才详情页面-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁情</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/talents'}">能人介绍</el-breadcrumb-item>
                <el-breadcrumb-item >能人列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="p-list w-1400">
            <div class="p-item clearfix" v-for="item in pageData.list" :key="item.id">
                <router-link :to="`/detail?id=${item.id}&type=能人介绍&oParams=${JSON.stringify(pageParams)}`">
                    <div class="p-pic fl">
                        <el-image style="width:100%;height:100%" :src="baseUrl+item.photoUrls"></el-image>
                    </div>
                    <div class="p-info fr">
                        <div class="p-h4">{{item.title}}</div>
                        <div class="p-text" >
                            {{regHtml(item.content)}}
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <el-pagination class="pagination" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>
    </div>
</template>
<script>
import thirdNav from '@/components/thirdNav'
import {GetTalents} from '@/api/xiangningqing'
import {regHtml} from '@/utils/reg'
export default {
    components:{
        thirdNav
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
    data(){
        return{
            pageParams:{
                currentPage: 1,
                filter: {title: "", infoType: 249252335243333},
                pageSize: 10,
            },
            pageData:{},
        }
    },
    methods:{
        getPageList(){
            GetTalents(this.pageParams).then(res=>{
                this.pageData = res.data.data;
            })
        },
        //分页
        handleCurrentChange(page) {
            this.pageParams.currentPage = page;
            this.getPageList();
        },
    },
    created(){
        this.getPageList();
    }
}
</script>
<style scoped>
.p-text{
    line-height: 36px;
    font-size: 18px;
    text-indent: 30px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:6;
    -webkit-box-orient:vertical;
}
.p-h4{
    line-height: 50px;
    font-size: 22px;
}
.p-info{
    width: calc(100% - 270px);
    height: 300px;
    padding: 5px 20px;
    overflow: hidden;
}
.p-pic{
    width: 270px;
    height: 300px;
    border-radius: 5px;
}
.p-item{
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 20px;
}
.picbox{
    position: absolute;
    top: -220px;
    left: 0;
    width: 240px;
    height: 220px;
    background: url(../../../../public/img/xnq/menu1.png) no-repeat center center;
    border: 3px solid #dc5238;
}
.submenu-item:nth-child(1){background: #dc5238;}
.submenu-item:nth-child(2){background: #f89807;}
.submenu-item:nth-child(3){background: #1db59b;}
.submenu-item{
    width: 240px;
    text-align: center;
    color: #fff;
    position: relative;
}
.submenu{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 50px;
    background: #00a0e9;
    font-size: 22px;
    justify-content: center;
}
.banner{
    height: 630px;
    background: url(../../../../public/img/xnq/banner1.png) no-repeat center center;
}
.bestPerson header{
    position: relative;
    height: 630px;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>